

 .nav1, .menu1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav1 {
    position: relative;
    /* background-color: #fff; */
    /* padding: 20px; */
    transition: 0.5s;
    /* border-radius: 50px; */
    overflow: hidden;
    box-shadow: 0 8px 15px rgba(0,0,0,.2);
}

.menu1 {
    margin: 0;
    padding: 0;
    width: 0;
    overflow: hidden;
    transition: 0.5s;
}

.nav1 input:checked ~ .menu1 {
    width: 450px;
}

.menu1 li {
    list-style: none;
    margin: 0 7px;
    font-size: 15px;
}

.menu1 li a {
    text-decoration: none;
    color:white;
    text-transform: uppercase;
    /* font-weight: 600; */
    transition: 0.5s;
}

.menu1 li a:hover {
   
}

.nav1 input {
    width: 61px;
    /* height: 40px; */
    cursor: pointer;
    opacity: 0;
}

.nav1 span {
    position: absolute;
    left: 30px;
    width: 30px;
    height: 4px;
    border-radius: 50px;
    background-color: #666;
    pointer-events: none;
    transition: 0.5s;
}

.nav1 input:checked ~ span {
    background-color: #f974a1;
}

.nav1 span:nth-child(2) {
    transform: translateY(-8px);
}

.nav1 input:checked ~ span:nth-child(2) {
    transform: translateY(0) rotate(-45deg);
}
.nav1 span:nth-child(3) {
    transform: translateY(8px);
}

.nav1 input:checked ~ span:nth-child(3) {
    transform: translateY(0) rotate(45deg);
}

@media only screen and (max-width:550px) {
   .xx{
    margin-left: -22px !important;
   }
}
.nav1 input:checked ~ .menu1 {
    width: 297px;
}